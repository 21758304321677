import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import weddingdayImages from '../images/weddingday'

const WeddingPictures = () => (
  <Layout className="wedding-pictures">
    <SEO title="Wedding Day Pictures" />
    <h1>Wedding Day Pictures</h1>
    <div className="wedding-pictures-container box-container">
      <h2>
          Finally...
      </h2>
      <div className="wedding-pictures-img-container">
        {weddingdayImages.map(src =>
          <img src={src} alt='wedding-pictures' className="wedding-pictures-img" />
        )}
      </div>
    </div>
  </Layout>
)

export default WeddingPictures
